/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-self-assign */
import React, { useRef, useEffect, useState } from "react";
import axios from "axios"
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import { LayoutSplashScreen } from "../../../../_common/layout";
import * as auth from "./authRedux";
import { getUserByToken, validateFormLinkApi, getAppversion, dashboardInviteApiMethod, getUserByTokenForInvites } from "./authCrud";
import { getregionsMethod } from "../_redux/commonCode"

function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  let { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),
    shallowEqual
  );
  const endPointUrl = useSelector((state) => state.auth.end_point_url, shallowEqual)
  const colourTheme = useSelector((state) => state.auth.colour_them, shallowEqual)
  useEffect(() => {
    if (window.location.pathname !== "/auth/user/VideoCall") {
      getregionsMethod().then(data => { !authToken && sessionStorage.setItem("end_point", data?.BaseURL) }).catch(err => { sessionStorage.setItem("end_point", null) })
    }
    setTimeout(() => {
      getAppversion().then(data => { sessionStorage.setItem("VersionNumber", data?.data?.VersionNumber) })
    }, 1000)
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // storing end point when user opens other window
  useEffect(() => {
    let queryParams_ = new URLSearchParams(window.location.search)
    const pathname = window.location.pathname
    sessionStorage.setItem("end_point", endPointUrl)
    // for  esource base url as it coming from mobile
    if (queryParams_.get("is_mob") === "true" && (pathname === "/form-details" || pathname === "/Form_details")) {
      const urlForForms = queryParams_.get("host")
      sessionStorage.setItem("end_point", "https://" + urlForForms)
      dispatch(props.end_point("https://" + urlForForms));
    }
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endPointUrl]);

  // for theme colour
  useEffect(() => {

    const sidemenuColur = colourTheme?.Branding_Color;
    const linkColour = colourTheme?.HighLight_Color;
    const footerColur = colourTheme?.Accent_Color;
    document.documentElement.style.setProperty('--col1-color', sidemenuColur ? sidemenuColur : '#0C2339');
    document.documentElement.style.setProperty('--col2-color', linkColour ? linkColour : '#F68723');
    document.documentElement.style.setProperty('--col3-color', footerColur ? footerColur : '#21B3C4');

  }, [colourTheme]);

  const pathname = window.location.pathname
  const queryParams = new URLSearchParams(window.location.search)
  let PatientCtmsid = queryParams.get("PatientCTMSID_")
  let name = queryParams.get("name")
  let ctmsHostLink = queryParams.get("CTMSHostLink_")
  let formidPortal = queryParams.get("ID_")
  let patientEmail = queryParams.get("Email")
  let authorizationToken = queryParams.get("AuthToken")
  authorizationToken = authorizationToken ? decodeURIComponent(authorizationToken) : ''
  // to set col theme for esource form from mobile
  let colourCode = {
    Branding_Color: "#" + queryParams.get("BC"),
    Accent_Color: "#" + queryParams.get("AC"),
    HighLight_Color: "#" + queryParams.get("HC"),
  }

  let id = queryParams.get("id")
  let siteId = queryParams.get("site_id")
  let isMobileView = queryParams.get("is_mob")
  let siteIDLocal = queryParams.get("SiteID_local_")
  let formToken = queryParams.get("token")
  // We should request user by authToken before rendering the application
  useEffect(() => {
    sessionStorage.setItem("mobile_form_is_open", "no")
    if (pathname === "/auth/user/site/feedback" || pathname === "/auth/user/VideoCall" || pathname === "/auth/user/policy" || pathname === "/auth/user/cookies" || pathname === "/auth/user/terms" || pathname === "/auth/reset-password") {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      authToken = undefined
    }
    if (pathname === "/form-details" || pathname === "/Form_details") {
      if (isMobileView === "true") {
        authToken = "ot"
        localStorage.clear()
        localStorage.setItem("AuthToken", authorizationToken)
        const decodedEmail = decodeURIComponent(patientEmail)
        localStorage.setItem("Email_address", decodedEmail)
        localStorage.setItem("CTMSHostLink", "https://" + ctmsHostLink)
        localStorage.setItem("PatientCTMSID", PatientCtmsid)
        localStorage.setItem("PatientName", name)
        sessionStorage.setItem("SiteID_local", siteIDLocal)
        localStorage.setItem("id", id)
        sessionStorage.setItem("mobile_form_is_open", "yes")
        // to set theme colour for esource
        dispatch(props.settheme(colourCode));
      }
    }
    if (pathname === "/dashboard") {
      if (queryParams.get("view") === "secondary") {
        axios.defaults.headers.common['Email'] = localStorage.getItem("Email_address");

        sessionStorage.setItem("is_admin_control", "true")
        sessionStorage.setItem("study_id_for_pp", queryParams.get("study_id_for_pp"))
        sessionStorage.setItem("end_point", queryParams.get("host"))
        dispatch(props.end_point(queryParams.get("host")));
        // setting base url when admin opens users account
      }
    }
    if (pathname === "/auth/user/VideoCall") {
      const baseurl = "https://" + queryParams.get("host")
      sessionStorage.setItem("end_point", baseurl)
      dispatch(props.end_point(baseurl));
    }
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          const { data: user } = await getUserByToken(id, siteId);
          const localDtaeLogin = localStorage.getItem("lastLoginTime")
          //  to check for inactivity for more than 20 min
          if (localDtaeLogin && isMobileView !== "true") {
            const currentDate = new Date()
            const storedDate = new Date(localDtaeLogin)
            if (currentDate.getTime() - storedDate.getTime() > 1200000) {//1200000=20min
              dispatch(props.fulfillUser(undefined));
              localStorage.clear()

            } else {
              let siteIdValue = localStorage.getItem("site_id")
              siteIdValue !== null ? siteIdValue = JSON.parse(siteIdValue) : siteIdValue = siteIdValue;
              if (pathname === "/user-profile/personal-information-readonly" && !siteIdValue) {
                const { data: inviteData } = await dashboardInviteApiMethod()
                const { data: newUser } = await getUserByTokenForInvites(localStorage.getItem("id"), inviteData[1]?.studyInvites[0]?.sites?.id);
                dispatch(props.fulfillUser(newUser));
              }
              else {
                dispatch(props.fulfillUser(user));
              }
            }

          }
          else {
            dispatch(props.fulfillUser(user));

          }
        }
      } catch (error) {

        if (!didRequest.current) {
          dispatch(props.logout());
        }
      } finally {
        setShowSplashScreen(false);
      }

      return () => (didRequest.current = true);
    };

    if (authToken) {
      // we are checking whether the form link is valid or not // when form is opened from mobile
      if ((pathname === "/form-details" || pathname === "/Form_details") && isMobileView === "true") {
        validateFormLinkApi(formidPortal, id, formToken).then(data => {
          sessionStorage.setItem("is_form_link_vliad", "yes")
          requestUser();
        }).catch(err => {
          // when reload happens we dont want to validate link again
          if (sessionStorage.getItem("is_form_link_vliad") === "yes") {
            requestUser();

          } else {
            dispatch(props.fulfillUser(undefined));
            setShowSplashScreen(false);
          }

        })
      } else {
        requestUser();

      }
    } else {
      dispatch(props.fulfillUser(undefined));
      setShowSplashScreen(false);

      if (pathname === "/meeting") {
        let meetingUrl = window.location.href
        sessionStorage.setItem("meeting_url", meetingUrl)

      }
    }
    // eslint-disable-next-line
  }, []);

  return showSplashScreen ? <LayoutSplashScreen /> : <>{props.children}</>;
}
export default connect(null, auth.actions)(AuthInit);

