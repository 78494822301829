import { decryptString } from "../app/modules/Auth/_redux/commonCode";

export default function setupAxios(axios, store) {
  axios.interceptors.request.use(
    config => {
      const {
        auth: { authToken }
      } = store.getState();

      const localToken = localStorage.getItem("AuthToken");
      const token = authToken || (localToken && localToken !== 'undefined' ? decryptString(localToken) : null);

      if (token) {
        config.headers["x-access-token"] = `${token}`;
        config.headers["email"] = sessionStorage.getItem("admin_email") ? sessionStorage.getItem("admin_email") : localStorage.getItem("Email_address");

      }

      return config;
    },
    err => Promise.reject(err)
  );
}
