import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { LayoutSplashScreen } from "../../../../_common/layout";
import { clearPushNotificationApi } from "../_redux/authCrud";
import * as auth from "../_redux/authRedux";
class Logout extends Component {
  componentDidMount() {
    let timeout = sessionStorage.getItem("sesson_logout_for_api")
    clearPushNotificationApi(timeout, null, this.props.authToken)

    setTimeout(() => {
      this.props.logout();
      localStorage.clear()
      sessionStorage.clear()
      sessionStorage.removeItem("sesson_logout_for_api");
    }, 1000);

    const sideMenu = document.getElementById("kt_body");
    if (sideMenu.classList.contains("aside-minimize")) {
      sideMenu.classList.remove("aside-minimize");
    }

    const { location } = this.props;

    if (location && location?.state?.isLastStudy) {
      sessionStorage.setItem("isLastStudy", "true")
    }
  }

  render() {
    const { hasAuthToken } = this.props;
    return hasAuthToken ? <LayoutSplashScreen /> : <Redirect to="/auth/login" />;
  }
}

export default connect(
  ({ auth }) => ({ hasAuthToken: Boolean(auth.authToken), authToken: auth.authToken }),
  auth.actions
)(withRouter(Logout));
